export const photoUrls = [
  'https://i.imgur.com/D0YmgCC.jpg',
  'https://i.imgur.com/7o3ZA8E.jpg',
  'https://i.imgur.com/ifgWeFG.jpg',
  'https://i.imgur.com/efS4knQ.jpg',
  'https://i.imgur.com/PnJXx2a.jpg',
  'https://i.imgur.com/kGBGuHP.jpg',
  'https://i.imgur.com/hZZfneY.jpg',
  'https://i.imgur.com/wt8KcX4.jpg',
  'https://i.imgur.com/zNDu5vb.jpg',
  'https://i.imgur.com/nyeRJUe.jpg',
  'https://i.imgur.com/VyI9hUP.jpg',
  'https://i.imgur.com/HwqgPoZ.jpg',
  'https://i.imgur.com/Ne6gQky.jpg',
  'https://i.imgur.com/3lNFW6H.jpg',
  'https://i.imgur.com/PawVSm6.jpg',
  'https://i.imgur.com/gjZwzAh.jpg',
  'https://i.imgur.com/6zNcViH.jpg',
  'https://i.imgur.com/mrAfxcp.jpg',
  'https://i.imgur.com/3K1IFIf.jpg',
  'https://i.imgur.com/imcaUHO.jpg',
  'https://i.imgur.com/tnSxPZB.jpg',
  'https://i.imgur.com/cKbRgEZ.jpg',
  'https://i.imgur.com/V6iMoGM.jpg',
  'https://i.imgur.com/PhnTg7N.jpg',
  'https://i.imgur.com/VMeGZaK.jpg',
  'https://i.imgur.com/esLGkT8.jpg',
  'https://i.imgur.com/xYffKUU.jpg',
  'https://i.imgur.com/C7z9N1w.jpg',
  'https://i.imgur.com/ETLYsDC.jpg',
  'https://i.imgur.com/gaKFYsY.jpg',
  'https://i.imgur.com/4yBEX74.jpg',
  'https://i.imgur.com/VSkTDTs.jpg',
  'https://i.imgur.com/nzsDaXl.jpg',
  'https://i.imgur.com/gtKYVxw.jpg',
  'https://i.imgur.com/MFMyc3H.jpg',
  'https://i.imgur.com/zakpw2K.jpg',
  'https://i.imgur.com/TKTFABB.jpg',
  'https://i.imgur.com/Yk2TwDm.jpg',
  'https://i.imgur.com/X7ep4Zu.jpg',
  'https://i.imgur.com/mX5qF39.jpg',
  'https://i.imgur.com/6sZW0Nq.jpg',
  'https://i.imgur.com/pICzdPP.jpg',
  'https://i.imgur.com/WofdjIB.jpg',
  'https://i.imgur.com/CRBlUbV.jpg',
  'https://i.imgur.com/iTUKPpW.jpg',
  'https://i.imgur.com/UKQklOT.jpg',
  'https://i.imgur.com/aLf2YqC.jpg',
  'https://i.imgur.com/aqWbpGr.jpg',
  'https://i.imgur.com/6a8ooYH.jpg',
  'https://i.imgur.com/OSPmoBr.jpg',
  'https://i.imgur.com/lQ8Mdc6.jpg',
  'https://i.imgur.com/EgF5ueT.jpg',
  'https://i.imgur.com/0IWCjFu.jpg',
  'https://i.imgur.com/g09arAC.jpg',
  'https://i.imgur.com/BFhsel5.jpg',
  'https://i.imgur.com/7D1ZOWX.jpg',
  'https://i.imgur.com/M6dLVGc.jpg',
  'https://i.imgur.com/aGARWXF.jpg',
  'https://i.imgur.com/TwNsBiW.jpg',
  'https://i.imgur.com/hynSVZa.jpg',
  'https://i.imgur.com/oEK2xz8.jpg',
  'https://i.imgur.com/IFL7W2D.jpg',
  'https://i.imgur.com/Z1Gmf8p.jpg',
  'https://i.imgur.com/398q4Rv.jpg',
  'https://i.imgur.com/wzpsLgx.jpg',
  'https://i.imgur.com/HGwKD3n.jpg',
  'https://i.imgur.com/nUmcPrh.jpg',
  'https://i.imgur.com/DIXX0a4.jpg',
  'https://i.imgur.com/Chi5DwG.jpg',
  'https://i.imgur.com/Lnu9P5O.jpg',
  'https://i.imgur.com/cIilQzR.jpg',
  'https://i.imgur.com/w2fHjRc.jpg',
  'https://i.imgur.com/a6gNqQV.jpg',
  'https://i.imgur.com/vfDqggv.jpg',
  'https://i.imgur.com/AH9Fzy9.jpg',
  'https://i.imgur.com/MwiWlRf.jpg',
  'https://i.imgur.com/YitzyUH.jpg',
  'https://i.imgur.com/5GKyAQw.jpg',
  'https://i.imgur.com/qKv16OL.jpg',
  'https://i.imgur.com/4HwjM8X.jpg',
  'https://i.imgur.com/x0GoyzT.jpg',
  'https://i.imgur.com/6fuwdvK.jpg',
  'https://i.imgur.com/LE26rKx.jpg',
  'https://i.imgur.com/bc52b5S.jpg',
  'https://i.imgur.com/pn85vRQ.jpg',
  'https://i.imgur.com/GkNDLsr.jpg',
  'https://i.imgur.com/a1i1bmn.jpg',
  'https://i.imgur.com/lMuk9li.jpg',
  'https://i.imgur.com/3ZFlKJT.jpg',
  'https://i.imgur.com/eVb0TM0.jpg',
  'https://i.imgur.com/9mprZvj.jpg',
  'https://i.imgur.com/UIQp5ps.jpg',
  'https://i.imgur.com/DqiB4lf.jpg',
  'https://i.imgur.com/GVyVaXd.jpg',
  'https://i.imgur.com/eurlFdP.jpg',
  'https://i.imgur.com/4NzqsHU.jpg',
  'https://i.imgur.com/YKStEyW.jpg',
  'https://i.imgur.com/jlMBemP.jpg',
  'https://i.imgur.com/EW71I4M.jpg',
  'https://i.imgur.com/Lyac6Dw.jpg',
  'https://i.imgur.com/u5crAsw.jpg',
  'https://i.imgur.com/8tvj15m.jpg',
  'https://i.imgur.com/MZeoBGq.jpg',
  'https://i.imgur.com/vRjpDDO.jpg',
  'https://i.imgur.com/mm6ZsKW.jpg',
  'https://i.imgur.com/LwdgnZc.jpg',
  'https://i.imgur.com/2TbgPY1.jpg',
  'https://i.imgur.com/dTNfS7f.jpg',
  'https://i.imgur.com/RJ3ytYe.jpg',
  'https://i.imgur.com/4E1zdYK.jpg',
  'https://i.imgur.com/Hs8AD70.jpg',
  'https://i.imgur.com/h0OkHBa.jpg',
  'https://i.imgur.com/w8H5YR7.jpg',
  'https://i.imgur.com/B0bLJEa.jpg',
  'https://i.imgur.com/5ciQeV0.jpg',
  'https://i.imgur.com/re8cyjN.jpg',
  'https://i.imgur.com/3otAZ9H.jpg',
  'https://i.imgur.com/HY5J91l.jpg',
  'https://i.imgur.com/JvNMv9I.jpg',
  'https://i.imgur.com/lERfBOg.jpg',
  'https://i.imgur.com/klLL0mc.jpg',
  'https://i.imgur.com/BVYjTIS.jpg',
  'https://i.imgur.com/HrgJ5XD.jpg',
  'https://i.imgur.com/Z9QLEPL.jpg',
  'https://i.imgur.com/feT3htH.jpg',
  'https://i.imgur.com/7nxrenB.jpg',
  'https://i.imgur.com/qsiQAu8.jpg',
]

export const thumbnails = [
  'https://i.imgur.com/D0YmgCCl.jpg',
  'https://i.imgur.com/7o3ZA8El.jpg',
  'https://i.imgur.com/ifgWeFGl.jpg',
  'https://i.imgur.com/efS4knQl.jpg',
  'https://i.imgur.com/PnJXx2al.jpg',
  'https://i.imgur.com/kGBGuHPl.jpg',
  'https://i.imgur.com/hZZfneYl.jpg',
  'https://i.imgur.com/wt8KcX4l.jpg',
  'https://i.imgur.com/zNDu5vbl.jpg',
  'https://i.imgur.com/nyeRJUel.jpg',
  'https://i.imgur.com/VyI9hUPl.jpg',
  'https://i.imgur.com/HwqgPoZl.jpg',
  'https://i.imgur.com/Ne6gQkyl.jpg',
  'https://i.imgur.com/3lNFW6Hl.jpg',
  'https://i.imgur.com/PawVSm6l.jpg',
  'https://i.imgur.com/gjZwzAhl.jpg',
  'https://i.imgur.com/6zNcViHl.jpg',
  'https://i.imgur.com/mrAfxcpl.jpg',
  'https://i.imgur.com/3K1IFIfl.jpg',
  'https://i.imgur.com/imcaUHOl.jpg',
  'https://i.imgur.com/tnSxPZBl.jpg',
  'https://i.imgur.com/cKbRgEZl.jpg',
  'https://i.imgur.com/V6iMoGMl.jpg',
  'https://i.imgur.com/PhnTg7Nl.jpg',
  'https://i.imgur.com/VMeGZaKl.jpg',
  'https://i.imgur.com/esLGkT8l.jpg',
  'https://i.imgur.com/xYffKUUl.jpg',
  'https://i.imgur.com/C7z9N1wl.jpg',
  'https://i.imgur.com/ETLYsDCl.jpg',
  'https://i.imgur.com/gaKFYsYl.jpg',
  'https://i.imgur.com/4yBEX74l.jpg',
  'https://i.imgur.com/VSkTDTsl.jpg',
  'https://i.imgur.com/nzsDaXll.jpg',
  'https://i.imgur.com/gtKYVxwl.jpg',
  'https://i.imgur.com/MFMyc3Hl.jpg',
  'https://i.imgur.com/zakpw2Kl.jpg',
  'https://i.imgur.com/TKTFABBl.jpg',
  'https://i.imgur.com/Yk2TwDml.jpg',
  'https://i.imgur.com/X7ep4Zul.jpg',
  'https://i.imgur.com/mX5qF39l.jpg',
  'https://i.imgur.com/6sZW0Nql.jpg',
  'https://i.imgur.com/pICzdPPl.jpg',
  'https://i.imgur.com/WofdjIBl.jpg',
  'https://i.imgur.com/CRBlUbVl.jpg',
  'https://i.imgur.com/iTUKPpWl.jpg',
  'https://i.imgur.com/UKQklOTl.jpg',
  'https://i.imgur.com/aLf2YqCl.jpg',
  'https://i.imgur.com/aqWbpGrl.jpg',
  'https://i.imgur.com/6a8ooYHl.jpg',
  'https://i.imgur.com/OSPmoBrl.jpg',
  'https://i.imgur.com/lQ8Mdc6l.jpg',
  'https://i.imgur.com/EgF5ueTl.jpg',
  'https://i.imgur.com/0IWCjFul.jpg',
  'https://i.imgur.com/g09arACl.jpg',
  'https://i.imgur.com/BFhsel5l.jpg',
  'https://i.imgur.com/7D1ZOWXl.jpg',
  'https://i.imgur.com/M6dLVGcl.jpg',
  'https://i.imgur.com/aGARWXFl.jpg',
  'https://i.imgur.com/TwNsBiWl.jpg',
  'https://i.imgur.com/hynSVZal.jpg',
  'https://i.imgur.com/oEK2xz8l.jpg',
  'https://i.imgur.com/IFL7W2Dl.jpg',
  'https://i.imgur.com/Z1Gmf8pl.jpg',
  'https://i.imgur.com/398q4Rvl.jpg',
  'https://i.imgur.com/wzpsLgxl.jpg',
  'https://i.imgur.com/HGwKD3nl.jpg',
  'https://i.imgur.com/nUmcPrhl.jpg',
  'https://i.imgur.com/DIXX0a4l.jpg',
  'https://i.imgur.com/Chi5DwGl.jpg',
  'https://i.imgur.com/Lnu9P5Ol.jpg',
  'https://i.imgur.com/cIilQzRl.jpg',
  'https://i.imgur.com/w2fHjRcl.jpg',
  'https://i.imgur.com/a6gNqQVl.jpg',
  'https://i.imgur.com/vfDqggvl.jpg',
  'https://i.imgur.com/AH9Fzy9l.jpg',
  'https://i.imgur.com/MwiWlRfl.jpg',
  'https://i.imgur.com/YitzyUHl.jpg',
  'https://i.imgur.com/5GKyAQwl.jpg',
  'https://i.imgur.com/qKv16OLl.jpg',
  'https://i.imgur.com/4HwjM8Xl.jpg',
  'https://i.imgur.com/x0GoyzTl.jpg',
  'https://i.imgur.com/6fuwdvKl.jpg',
  'https://i.imgur.com/LE26rKxl.jpg',
  'https://i.imgur.com/bc52b5Sl.jpg',
  'https://i.imgur.com/pn85vRQl.jpg',
  'https://i.imgur.com/GkNDLsrl.jpg',
  'https://i.imgur.com/a1i1bmnl.jpg',
  'https://i.imgur.com/lMuk9lil.jpg',
  'https://i.imgur.com/3ZFlKJTl.jpg',
  'https://i.imgur.com/eVb0TM0l.jpg',
  'https://i.imgur.com/9mprZvjl.jpg',
  'https://i.imgur.com/UIQp5psl.jpg',
  'https://i.imgur.com/DqiB4lfl.jpg',
  'https://i.imgur.com/GVyVaXdl.jpg',
  'https://i.imgur.com/eurlFdPl.jpg',
  'https://i.imgur.com/4NzqsHUl.jpg',
  'https://i.imgur.com/YKStEyWl.jpg',
  'https://i.imgur.com/jlMBemPl.jpg',
  'https://i.imgur.com/EW71I4Ml.jpg',
  'https://i.imgur.com/Lyac6Dwl.jpg',
  'https://i.imgur.com/u5crAswl.jpg',
  'https://i.imgur.com/8tvj15ml.jpg',
  'https://i.imgur.com/MZeoBGql.jpg',
  'https://i.imgur.com/vRjpDDOl.jpg',
  'https://i.imgur.com/mm6ZsKWl.jpg',
  'https://i.imgur.com/LwdgnZcl.jpg',
  'https://i.imgur.com/2TbgPY1l.jpg',
  'https://i.imgur.com/dTNfS7fl.jpg',
  'https://i.imgur.com/RJ3ytYel.jpg',
  'https://i.imgur.com/4E1zdYKl.jpg',
  'https://i.imgur.com/Hs8AD70l.jpg',
  'https://i.imgur.com/h0OkHBal.jpg',
  'https://i.imgur.com/w8H5YR7l.jpg',
  'https://i.imgur.com/B0bLJEal.jpg',
  'https://i.imgur.com/5ciQeV0l.jpg',
  'https://i.imgur.com/re8cyjNl.jpg',
  'https://i.imgur.com/3otAZ9Hl.jpg',
  'https://i.imgur.com/HY5J91ll.jpg',
  'https://i.imgur.com/JvNMv9Il.jpg',
  'https://i.imgur.com/lERfBOgl.jpg',
  'https://i.imgur.com/klLL0mcl.jpg',
  'https://i.imgur.com/BVYjTISl.jpg',
  'https://i.imgur.com/HrgJ5XDl.jpg',
  'https://i.imgur.com/Z9QLEPLl.jpg',
  'https://i.imgur.com/feT3htHl.jpg',
  'https://i.imgur.com/7nxrenBl.jpg',
  'https://i.imgur.com/qsiQAu8l.jpg',
]

